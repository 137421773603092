module.exports = [{
      plugin: require('../plugins/gatsby-source-dynamodb-query/gatsby-browser.js'),
      options: {"plugins":[],"typeName":"inventory","region":"us-east-2","tableName":"AdjustedInventory","dealerId":["1016038","056887"]},
    },{
      plugin: require('../plugins/gatsby-source-dynamodb-query/gatsby-browser.js'),
      options: {"plugins":[],"typeName":"altInventory","region":"us-east-2","tableName":"AdjustedInventory","dealerId":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"static/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e36917f936c9adc9bb529b00d1b429fd"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
